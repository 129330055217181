import querystring from 'querystring'
import {
  Value, 
  PopulatedValue,
  Indicator, 
  Company, 
  MatchedPlaidTransaction, 
  User, 
  UserAnswer,
  Dictionary,
  DailyUserSummary,
  Datasource,
  FeaturedTag,
  UserSignupPayload,
  API,
  CachedDealPointer,
  IImpactArea,
  DashboardTransactionSummary,
  DashboardAccountInfo,
} from '@ecountabl/lib'

const HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

type UserUpdateResponse = {
  user: User;
  token?: string;
  msg?: string;
}

export enum PlaidAccountStatus {
  New = 'NEW',
  InitialUpdate = 'INITIAL_UPDATE',
  HistoricalUpdate = 'HISTORICAL_UPDATE',
  Error = 'ERROR'
}

export type TransactionResponse = {
  status?: PlaidAccountStatus;
  transactions: MatchedPlaidTransaction[];
}

export type TransactionsByDateResponse = {
  transactions: {date: string, transactions: MatchedPlaidTransaction[]}[];
  canFetchMore: boolean;
}

export type ApiConstructorOpts = {
  token?: string;
  endpoint: string;
  onTokenUpdate?: (token: string) => Promise<void>;
  onTokenError?: (message: string) => Promise<void>;

  // returns a string representing the token
  asyncGetToken?: () => Promise<string>;
}

export type Institution = {
  id: string;
  name: string;
}

export type GetIndicatorsResponse = {
  byAbbreviation: Dictionary<Indicator>;
  byCategory: Dictionary<string[]>;
  indicators: Indicator[];
}

type GetCompanyInfoParams = {
  maxAlts?: number
}

export type ExchangePlaidPublicTokenResponse = {
  item_id: string;
}

type ValuesWithQuestionsResponse = {
  values: Value[];
  userAnswers: UserAnswer[];
}

export interface AnalyticsProperties extends Record<string, any> {
  distinct_id?: string;
}

export type AnalyticsEvent = {
  event_name: string;
  properties?: AnalyticsProperties
}

type CoSearchQuery = string | {
  // tags should be a string of tag IDs
  tags?: string[];
  leadership?: string;
  // TODO: add more options here
}


class ApiClient {
  private token?: string;
  private endpoint?: string;
  
  // callback for consumers to persist their token via localstorage, cookie, whatever
  private onTokenUpdate?: (token: string) => Promise<void>

  // callback if token error or logout
  private onTokenError?: (message: string) => Promise<void>

  // async init of token if needed, like with expo SecureStore
  private asyncGetToken: () => Promise<string>;

  constructor(opts: ApiConstructorOpts) {
    this.token = opts.token
    this.endpoint = opts.endpoint
    this.onTokenUpdate = opts.onTokenUpdate
    this.onTokenError = opts.onTokenError
    this.asyncGetToken = opts.asyncGetToken
  }

  private _fetch(url: string, opts: RequestInit): Promise<any> {
    return new Promise((resolve, reject) => {
      fetch(url, opts)
        .then(async (res) => {
          let json
          let text

          try {
            text = await res.text()
            json = JSON.parse(text)
          } catch (ex) {
            console.log('error parsing JSON, text:', text)
            return reject(ex.message)
          }

          // console.log({ url, json, status: res.status })

          if (res.status >= 200 && res.status < 300) {
            return resolve(json)
          } else {
            json.status = res.status

            if (['jwt expired', 'invalid signature'].includes(json.tokenError)) {
              if (typeof this.onTokenError === 'function') {
                this.onTokenError(json.tokenError)
              }

              // clear the token if we detect it's no longer valid
              this.token = undefined
            }

            return reject(json)
          }
        })
        .catch((ex) => {
          reject(ex)
        })
    })
  }

  public authenticatedFetch(path: string, opts: any): Promise<any> {
    const headers = { Auth: this.token }

    return fetch(this.endpoint + path, {
      ...opts,
      headers: { ...opts?.headers, ...headers }
    })
  } 

  /**
   * Because some clients may need to fetch their token using an async method, 
   * we accommodate by enabling them to set the token asynchronously. Note that 
   * this will throw if the asyncGetToken call fails
   */
  private async initToken(): Promise<void> {
    if (!this.token && typeof this.asyncGetToken === 'function') {
      this.token = await this.asyncGetToken()
    }
  }

  public clearToken(): void {
    this.token = undefined
  }

  public setToken(token: string): void {
    this.token = token
  }

  async get(url: string, query?: object): Promise<any> {
    await this.initToken()

    let _url = url
    if (query) {
      _url += `?${querystring.stringify(query)}`
    }

    const headers = Object.assign({ Auth: this.token }, HEADERS)

    return this._fetch(this.endpoint + _url, {
      method: 'GET',
      headers,
    })
  }

  async update(url: string, body, method): Promise<any> {
    await this.initToken()

    const headers = Object.assign({ Auth: this.token }, HEADERS)

    return this._fetch(this.endpoint + url, {
      method,
      headers,
      body: JSON.stringify(body),
    })
  }

  post(url: string, body?: object): Promise<any> {
    return this.update(url, body, 'POST')
  }

  put(url: string, body?: object): Promise<any> {
    return this.update(url, body, 'PUT')
  }

  patch(url: string, body?: object): Promise<any> {
    return this.update(url, body, 'PATCH')
  }

  delete(url: string): Promise<any> {
    return this._fetch(this.endpoint + url, {
      method: 'DELETE',
      headers: Object.assign({}, HEADERS, {
        Auth: this.token
      }),
    })
  }

  public async sitemap(): Promise<any> {
    return this.get('/api/info/sitemap')
  }

  /**
   * Auth and Login methods
   */
  public async login(email: string, password: string): Promise<any> {
    const user = await this.post('/api/auth', { email, password })
    if (user.token) {
      this.token = user.token
      if (this.onTokenUpdate && typeof this.onTokenError === 'function') {
        this.onTokenUpdate(this.token)
      }
    }
    return user
  }

  /**
   * User methods
   */
  public getMe(): Promise<any> {
    return this.get(`/api/users/me`)
  }

  public getInstitutions(): Promise<any> {
    return this.get(`/api/users/me/institutions`)
  }

  public getAccounts(): Promise<{
    accounts: DashboardAccountInfo[], errors: {code: number, msg: string}[]
  }> {
    return this.get(`/api/users/me/accounts`)
  }

  public resetPassword(password: string, token: string): Promise<any> {
    return this.post(`/api/reset-password/${token}`, { password })
  }

  public requestResetPassword(email: string): Promise<any> {
    return this.post(`/api/reset-password`, { email })
  }

  public unlinkInstitution(item_id: string): Promise<any> {
    return this.post(`/api/users/me/institutions/${item_id}/unlink`)
  }

  public resetFinancialAccounts(): Promise<API.DefaultResponse> {
    return this.post('/api/users/me/reset-financial-accounts')
  }

  public signUp(user: UserSignupPayload): Promise<UserUpdateResponse> {
    return this.post('/api/users', user)
  }

  public async updateUser(user: Partial<User>): Promise<UserUpdateResponse> {
    const resp = await this.patch('/api/users/me', user)

    this.token = resp.token
    if (this.onTokenUpdate && typeof this.onTokenError === 'function') {
      this.onTokenUpdate(this.token)
    }

    return resp
  }

  public async setPassword(password: string): Promise<UserUpdateResponse> {
    const resp = await this.put('/api/users/set-password', { password })

    this.token = resp.token
    if (this.onTokenUpdate && typeof this.onTokenError === 'function') {
      this.onTokenUpdate(this.token)
    }

    return resp
  }

  public getUserValues(): Promise<Dictionary<number>> {
    return this.get('/api/users/me/values')
  }

  public getScores(): Promise<any> {
    return this.get('/api/users/me/scores')
  }

  public getAccessToken(
    public_token: string,
    institution: Institution
  ): Promise<ExchangePlaidPublicTokenResponse> {
    return this.post('/api/users/plaidtoken', { public_token, institution })
  }

  public getPlaidLinkToken(itemId?: string): Promise<{ linkToken: string }> {
    return this.get('/api/users/plaidlinktoken', { itemId })
  }

  public onboard(): Promise<any> {
    return this.post('/api/users/me/onboard')
  }

  public setAppPermissions(permissions: object): Promise<any> {
    return this.patch('/api/users/me/apppermissions', permissions)
  }

  // gets a history of transactions based on a number of days,
  // then executes code once the data is retrieved
  public getTransactions(
    max: number,
    range: number,
    index: number
  ): Promise<TransactionResponse> {
    return this.get('/api/transactions', {
      count: max,
      index: index,
      days: range,
    })
  }

  public getDashboardTransactionSummary(): Promise<DashboardTransactionSummary> {
    return this.get(`/api/transactions/dashboard`)
  }

  public getTransactionsByDate(
    page: number,
    count: number,
    term?: string,
    sort?: string,
    filter?: string
  ): Promise<TransactionsByDateResponse> {
    return this.get('/api/transactions/bydate', { page, count, term, sort, filter })
  }

  public async acceptTerms(): Promise<UserUpdateResponse> {
    const res = await this.post('/api/users/me/acceptterms')

    if (res.token) {
      this.token = res.token

      if (this.onTokenUpdate) {
        this.onTokenUpdate(this.token)
      }
    }

    return res
  }

  public async setUserValues(values: Dictionary<number>): Promise<UserUpdateResponse> {
    const res = await this.patch('/api/users/me/values', { values })

    if (res.token) {
      this.token = res.token

      if (this.onTokenUpdate) {
        this.onTokenUpdate(this.token)
      }
    }

    return res
  }

  public async getImpactBankingData(): Promise<any> {
    return this.get('/api/users/me/impact-banking')
  }

  public async referBusiness(data: any): Promise<any> {
    return this.post('/api/users/me/refer-business', data)
  }

  public async getCustomerImpact(): Promise<any> {
    return this.get('/api/users/me/customer-impact')
  }

  /**
   * Plaid methods
   */
  public logPlaidMessage(msg: any): Promise<void> {
    return this.post(`/api/plaid/log`, msg)
  }

  /**
   * Company methods
   */
  public getCompanyInfo(
    companyID: string,
    params?: GetCompanyInfoParams
  ): Promise<{ company: Company }> {
    const id = encodeURIComponent(companyID)
    return this.get(`/api/info/${id}`, params)
  }

  public getSuggestedByValues(): Promise<{ companies: Company[] }> {
    return this.get(`/api/info/suggested`)
  }

  public getFeaturedTags(): Promise<FeaturedTag[]> {
    return this.get(`/api/tags/featured`)
  }

  public getScoreForValues(coId, values): Promise<any> {
    return this.post(`/api/info/${coId}/scoreforvalues`, values)
  }

  public getCompanyBySlug(slug: string): Promise<Company> {
    return this.get('/api/info/slug/' + slug)
  }

  public getRecentlyAddedBusinesses(): Promise<Company[]> {
    return this.get('/api/info/recent-companies')
  }

  public setUnknown(txid: string): Promise<any> {
    return this.put(`/api/info/unknown/${txid}`)
  }

  public setLocalBiz(txid: string): Promise<any> {
    return this.put(`/api/info/localbiz/${txid}`)
  }

  public linkCompanyToTransaction(companyId: string, txid: string): Promise<any> {
    return this.put(`/api/info/${companyId}/link/${txid}`)
  }

  public submitCompany(body: API.SubmitCompanyBody): Promise<API.DefaultResponse> {
    return this.post(`/api/info/submit`, body)
  }

  /**
   * Indicators
   */
  public getIndicators(q?: API.IndicatorQuery): Promise<GetIndicatorsResponse> {
    return this.get(`/api/indicators`, q)
  }

  public getIndicator(slugOrId: string): Promise<{ indicator: Indicator }> {
    return this.get(`/api/indicators/${slugOrId}`)
  }

  /**
   * Analytics
   * TODO: should this be in it's own module?? 
   */
  public trackEvent(data: AnalyticsEvent): Promise<any> {
    return this.post(`/api/analytics/track`, data)
  }

  /**
   * ImpactAreas
   */
  public getImpactAreas(): Promise<{ list: IImpactArea[] }> {
    return this.get('/api/impact-areas')
  }

  /**
   * Values
   */
  public getValues(): Promise<Value[]> {
    return this.get('/api/values')
  }

  public getValue(id: string): Promise<any> {
    return this.get(`/api/values/${id}`)
  }

  public getPopulatedValues(): Promise<PopulatedValue[]> {
    return this.get('/api/values/populated')
  }

  // Includes user answers and responses
  public getValuesWithQuestions(): Promise<ValuesWithQuestionsResponse> {
    return this.get('/api/values/with-questions')
  }

  /**
   * Daily Scores
   */
  public getDailyScores(): Promise<DailyUserSummary[]> {
    return this.get('/api/dailyscores')
  }

  /**
   * Deals
   */
  public async getCampaigns(tag?: string): Promise<API.DealsResponse> {
    if (tag) {
      const _tag = encodeURIComponent(tag)
      return this.get('/api/campaigns', { tag: _tag })
    }

    return this.get(`/api/campaigns`)
  }

  public async searchDeals(text: string): Promise<CachedDealPointer[] | API.DefaultResponse> {
    return this.get(`/api/campaigns/search`, {
      q: encodeURIComponent(text)
    })
  }

  /**
   * Datasources
   */
  public async getDatasources(): Promise<Datasource[]> {
    const ds = await this.get(`/api/datasources`)
    return ds.datasources
  }

  public getDataSource(id: string): Promise<any> {
    return this.get(`/api/datasources/${id}`)
  }

  /**
   * User Answers
   */
  public setUserAnswers(userAnswers: UserAnswer[]): Promise<any> {
    return this.put('/api/user-answers', userAnswers)
  }


  /**
   * Search
   */

  // searches the database for matching company names or industries
  public searchTerm(term: string, limit?: number): Promise<{ companies: Company[] }> {
    const opts: { limit?: number } = {}

    if (limit) {
      opts.limit = limit
    }

    const encodedTerm = encodeURIComponent(term)

    return this.get(`/api/search/regex/${encodedTerm}`, opts)
  }

  public tokenizedSearch(term: string, limit?: number): Promise<{ companies: Company[] }> {
    const opts: { limit?: number } = {}

    if (limit) {
      opts.limit = limit
    }

    const encodedTerm = encodeURIComponent(term)

    return this.get(`/api/search/tokenized/${encodedTerm}`, opts)
  }

  public companySearch(query: CoSearchQuery): Promise<Company[]> {
    if (typeof query === 'string') {
      return this.get('/api/search/regexortokenized/' + query)
    }

    return this.get('/api/search', {
      tags: JSON.stringify(query.tags),
      leadership: query.leadership
    })
  }

  public iAmEcountablStockImages(): Promise<Record[]> {
    return this.get('/api/files/img/iamecountabl')
  }
}

export default ApiClient
