import { ObjectID } from 'mongodb'
import { Dictionary } from './misc'
import { ITag, Indicator } from './indicator'
import { Company } from './company'
import * as FAQ from './faq'
import * as Leadership from './leadership'
import { AccountDetails, MatchedPlaidTransaction } from './plaid-transaction'
import { UserImpact } from './user'
import { DashboardAccountInfo } from './accounts'

export * from './plaid-transaction'
export * from './value'
export * from './question'
export * from './user'
export * from './datasource'
export * from './api'
export * from './deals'
export * from './indicator'
export * from './oauth'
export * from './misc'
export * from './company-upload'
export * from './company'
export * from './customer'
export * from './util'
export * from './mappings'
export * from './accounts'

export { FAQ, Leadership }

/* eslint-disable no-useless-escape */
export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i
export const PHONE_REGEX = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/

export type TxSummary = {
  amount: number;
  score: number;
}

export interface DailyUserSummary {
  userId?: ObjectID;
  date?: Date;
  total?: number; // sum(amount * score)
  outOf?: number;
  dailyScore?: number;
  rolling7Score?: number;
  rolling30Score?: number;
  txScoreMap: Dictionary<TxSummary>;
}


export type FeaturedTag = ITag & { companies: Company[] }

export interface ISegment {
  tags: ITag[];
  subsegments: string[];
  name: string;
}

export interface IApiKey {
  _id: string;
  label: string;
  usage?: Dictionary<number>;
  lastUsed: string;
  created: Date;
}

export interface SelectedIcon {
  family: string
  name: string
}

export interface IImpactArea {
  _id: string
  label: string
  // value where it's displayed in chooser
  valueSlug: string
  icon?: SelectedIcon
  description?: string
  impactLanguage?: string

  // needed for ui
  amount?: number
  hexColor?: string
}

export type UIImpactArea = IImpactArea & {
  indicators?: Indicator[]
}

export type MatchedAccountInfo = {
  institutionName: string,
  item_id: string,
  account: AccountDetails
}

export type DashboardImpactSummary = UserImpact & {
  accounts: DashboardAccountInfo[],
  transactions: MatchedPlaidTransaction[],
}