enum AccountStatus {
  New = 'NEW',
  InitialUpdate = 'INITIAL_UPDATE',
  HistoricalUpdate = 'HISTORICAL_UPDATE',
  Unlinking = 'UNLINKING',
  Error = 'ERROR'
}

export type AccountInstanceSummary = {
  account_id: string;
  name: string;
  institution: string;
}

export type FinastraAccountType = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
  scope: string;
  id_token: string;
  institution: {
    name: string;
    institution_id: string;
  },
  item_id: string;
  status: AccountStatus;
}

export type PlaidAccountType = {
  institution: {
    name: string;
    institution_id: string;
  };
  access_token: string;
  item_id: string;
  status: AccountStatus;
}

export type DashboardAccountInfo = {
  institution: string;
  fromCustomer: boolean;
  item_id: string;
  balances: {
    available?: number;
    current?: number;
  };
  accountName: string;
  accountType: string;
  accountSubType?: string;
  accountNumber: string;
}

export type UserAccount = PlaidAccountType | FinastraAccountType